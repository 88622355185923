import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { MediaModel } from '@fc-vehicles/service/media-model';

@Injectable({
  providedIn: 'root',
})
export class ChatAttachmentsApiService {
  private http = inject(HttpClient);
  private url = environment.apiUrl + 'api/files/chat_channels/';

  addFile(file: File, channelId: string): Promise<MediaModel> {
    const formData = new FormData();

    formData.append('original', file);

    if (file.name) {
      formData.append('name', file.name);
    }

    return lastValueFrom(
      this.http.post<MediaModel>(
        this.url + `${channelId}/attachments/`,
        formData,
      ),
    );
  }

  getChannelAttachments(channelId: string): Promise<MediaModel[]> {
    return lastValueFrom(
      this.http.get<MediaModel[]>(this.url + `${channelId}/attachments/`),
    );
  }

  getAttachment(channelId: string, id: string): Promise<MediaModel> {
    return lastValueFrom(
      this.http.get<MediaModel>(this.url + `${channelId}/attachments/${id}/`),
    );
  }

  deleteFile(channelId: string, id: string): Promise<void> {
    return lastValueFrom(
      this.http.delete<void>(this.url + `${channelId}/attachments/${id}/`),
    );
  }
}
