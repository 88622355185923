import { Injectable } from '@angular/core';
import { Store } from '@fc-core/store-utils/store';
import { MediaModel } from '@fc-vehicles/service/media-model';
import { create } from 'mutative';

export interface ChatAttachmentsState {
  attachments: MediaModel[];
  attachmentsLoading: boolean;
  attachmentsError: string;
}

const initialState: ChatAttachmentsState = {
  attachments: [],
  attachmentsLoading: false,
  attachmentsError: null,
};

@Injectable({
  providedIn: 'root',
})
export class ChatAttachmentsStore extends Store<ChatAttachmentsState> {
  constructor() {
    super(initialState);
  }

  setAttachments(attachments: MediaModel[]) {
    this.update({ attachments });
  }

  setAttachment(attachment: MediaModel) {
    const state = create(this._state(), (draft) => {
      draft.attachments = [...draft.attachments, attachment];
    });
    this.update(state);
  }

  deleteAttachment(id: number) {
    const state = create(this._state(), (draft) => {
      draft.attachments = draft.attachments.filter(
        (attachment) => attachment.id !== id,
      );
    });
    this.update(state);
  }
}
